<template>
  <v-container>
    <v-row>
      <v-col xxl="4" xl="4" lg="4" md="12" sm="12">
        <v-row>
          <v-col cols="12" :class="stacked ? 'col-center' : 'col-right'">
            <img src="../assets/mission-squad-icon1.png" class="left-max-width" />
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <!-- <v-col cols="2"></v-col> -->
          <v-col cols="12" :class="stacked ? 'col-center mt-0 pt-0' : 'col-right mt-0 pt-0'">
            <!-- <div class="left-max-width"> -->
            <v-list bg-color="background" class="col-left inline left-max-width width-350 px-2 mt-0 pt-0">
              <v-list-item>
                <p class="text-subtitle-1">
                  Mission Squad is an app that lets you use any AI model to create groups of agents that work together
                  to accomplish your tasks in minutes.
                  <br />
                  Compatible with OpenAI, Anthropic, Infermatic, LM Studio and others that use the
                  <a class="link-inline" href="https://platform.openai.com/docs/api-reference/chat/create">OpenAI API
                    spec</a>.
                </p>
              </v-list-item>
              <v-list-item>
                <p><b>Please always export your settings before upgrading!</b></p>
              </v-list-item>
              <v-list-item>
                <h2 class="text-h5">Download the desktop app</h2>
              </v-list-item>
              <v-list-item>
                <v-icon color="secondary" size="large" class="mt-n1 mr-2">mdi-apple</v-icon>
                <span class="os-links mt-2 text-body-1">
                  <a class="link" :href="`/releases/zip/darwin/arm64/Mission Squad-darwin-arm64-${version}.zip`"
                    download>
                    macOS (Apple Silicon) v{{ version }}
                  </a>
                </span>
              </v-list-item>
              <v-list-item>
                <v-icon color="secondary" size="large" class="mt-n1 mr-2">mdi-apple</v-icon>
                <span class="os-links mt-2 text-body-1">
                  <a class="link" :href="`/releases/zip/darwin/x64/Mission Squad-darwin-x64-${version}.zip`" download>
                    macOS (Intel) v{{ version }}
                  </a>
                </span>
              </v-list-item>
              <v-list-item>
                <v-icon color="secondary" size="large" class="mt-n1 mr-2">mdi-linux</v-icon>
                <span class="os-links mt-2 text-body-1">
                  <a class="link" :href="`/releases/zip/linux/x64/Mission Squad-linux-x64-${version}.zip`"
                    download>Linux
                    (x64/amd64 bin) v{{ version }}
                  </a>
                </span>
              </v-list-item>
              <v-list-item>
                <v-icon color="secondary" size="large" class="mt-n1 mr-2">mdi-linux</v-icon>
                <span class="os-links mt-2 text-body-1">
                  <a class="link" :href="`/releases/deb/x64/missionsquad_${version}_amd64.deb`" download>Linux
                    (x64/amd64 .deb) v{{ version }}
                  </a>
                </span>
              </v-list-item>
              <v-list-item>
                <v-icon color="secondary" size="large" class="mt-n1 mr-2">mdi-microsoft-windows</v-icon>
                <span class="os-links my-2 text-body-1">
                  <!-- <a class="link" href="`/releases/${version}/Mission Squad-${version} Setup.exe`">Windows (x64
                    v{{ version }})</a> -->
                  Windows
                </span>
                <div class="ml-9 mt-1">
                  <b>Native app coming soon.</b><br />
                  <router-link to="/how-to/windows">Install on Windows via WSL.</router-link>
                </div>
              </v-list-item>
              <!-- <v-list-item>
                <v-ov-icon color="#7efffe" name="fa-linux" scale="1.5" class="mr-2" />
                <span class="os-links mt-n1 text-body-1">
                  <a class="link" href="/#">Linux (coming soon)</a>
                </span>
              </v-list-item> -->
            </v-list>
            <!-- </div> -->
          </v-col>
          <!-- <v-col cols="2"></v-col> -->
        </v-row>
      </v-col>
      <v-col v-if="!hideScreenshots" xxl="8" xl="8" lg="8" md="12" sm="12">
        <img class="max-width-100" src="../assets/app-screenshots-group@1x.png" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  computed: {
    breakpoint() {
      return this.$vuetify.display.name
    }
  },
  watch: {
    breakpoint(value: any) {
      this.showStacked()
    }
  }
})
export default class HomeView extends Vue {
  breakpoint!: string
  stacked: boolean = false
  hideScreenshots: boolean = false
  version: string = '1.4.1'

  beforeCreate() {}

  async mounted() {
    // console.log(this.$vuetify.display)
  }

  showStacked() {
    const bp = this.breakpoint
    if (bp === 'md' || bp === 'sm' || bp === 'xs') {
      this.stacked = true
    } else {
      this.stacked = false
    }
    if (bp === 'xs') {
      this.hideScreenshots = true
    } else {
      this.hideScreenshots = false
    }
  }
}
</script>
<style scoped lang="scss">
.max-width-100 {
  max-width: 100%;
}
.left-max-width {
  max-width: 350px;
}
.width-350 {
  width: 350px;
}
.col-center {
  text-align: center;
}
.col-right {
  text-align: right;
}
.col-left {
  text-align: left;
}
.inline {
  display: inline-block;
}
.os-links {
  display: inline-block;
}
.link {
  color: #ff01b9;
}
.link-inline {
  color: #ffffff;
}
</style>
//https://discord.gg/GbGnkUec3e