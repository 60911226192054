import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { ThemeDefinition, createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// import { OhVueIcon, addIcons } from 'oh-vue-icons'
// import { FaLinux } from 'oh-vue-icons/icons'

// addIcons(FaLinux)

const synthWave: ThemeDefinition = {
  dark: true, // Consider setting this to true if you want a darker Synthwave theme
  colors: {
    background: '#211135', // Dark purple for the background
    surface: '#6903e2', // lighter purple for surfaces
    primary: '#ff01b9', // Neon pink for primary elements
    'primary-darken-1': '#ff0075', // A slightly darker shade of neon pink for primary darken
    secondary: '#7efffe', // Bright cyan for secondary elements
    'secondary-darken-1': '#20d0ff', // A slightly darker shade of cyan for secondary darken
    error: '#ff0033', // Bright red for errors
    info: '#2196F3', // You can keep info color or adjust to fit the theme
    success: '#00ff00', // Neon green for success messages
    warning: '#ffbe0b' // Neon yellow for warnings
  }
}

const veutify = createVuetify({
  theme: {
    defaultTheme: 'synthWave',
    themes: { synthWave }
  },
  components: {
    ...components
  },
  directives
})

// const theme = useTheme()
// theme.global.name.value = 'synthWaveTheme'

createApp(App).use(store).use(router).use(veutify).mount('#app')
// .component('v-ov-icon', OhVueIcon)
