<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>Feature Roadmap</h2>
        <p>
          Below you will find a list of features and capabilities we are working on. Not necessarily in the order shown.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-list>
          <v-list-item>
            <h3>Embeddings</h3>
            <p>
              The ability to embed data from files into models. Ideally this would work with all currently supported
              APIs.
            </p>
          </v-list-item>
          <v-list-item>
            <h3>Tools</h3>
            <p>
              Tool use or function calling. Ideally this will allow users to provide functions in JavaScript for a model
              to "use". There should be a
              basic set of tools by default. All models/APIs that can manage a consistent formatted response should be
              able to use these tools.
            </p>
          </v-list-item>
          <v-list-item>
            <h3>File handling</h3>
            <p>
              This will add functionality to upload and download files for use in Missions. For example, it would be
              useful to be able to upload
              a directory containing some scripts to be used either as a template by an agent, as a starting point for a
              mission or as tool functions.
            </p>
            <p>
              Similarly, it would be useful to be able to download a directory containing the results of a mission.
              Whether that's a PDF file or a
              directory containing output code from your squad. This will also enable more advanced outputs to be
              created as the squad can keep track of
              their progress when executing a mission that requires the output of multiple files.
            </p>
          </v-list-item>
          <v-list-item>
            <h3>Windows</h3>
            <p>
              The Windows version of Mission Squad is absolutely a priority, but it's falling behind some more powerful
              capabilities.
              This is mostly because the Linux version does work on Windows via WSL. However you may have to adjust your
              firewall settings and use the host's IP instead of localhost when applicable.
            </p>
          </v-list-item>
          <v-list-item>
            <h3>Longer term feature list...</h3>
            <p>Templates/Examples, API control, Plugins/External Interactions</p>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

export default class RoadmapView extends Vue {

  beforeCreate() { }

  async mounted() {

  }
}
</script>
<style scoped lang="scss">
.inline {
  display: inline-block;
  position: absolute;
  margin-top: -2px;
}

.v-input--density-compact {
  --v-input-control-height: 20px !important;
}

.link-inline {
  color: #ffffff;
}
</style>