<template>
  <v-app fluid>
    <v-container>
      <v-row>
        <v-col cols="12" class="col-center">
          <img src="./assets/mission-squad-logo2.png" class="max-width-100" alt="Mission Squad Logo" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="links">
          <div class="max-width-1250">
            <span class="link pr-4">
              <router-link to="/">Home</router-link>
            </span>
            <span class="link pr-4">
              <router-link to="/roadmap">Roadmap</router-link>
            </span>
            <span class="link pr-4">
              <router-link to="/how-to">How To</router-link>
            </span>
          </div>
        </v-col>
        <!-- <v-col cols="1">
        </v-col>
        <v-col cols="1">
        </v-col> -->
      </v-row>
    </v-container>
    <router-view />
  </v-app>
</template>

<script setup lang="ts">
import { useTheme } from 'vuetify'

const theme = useTheme()
theme.global.name.value = 'synthWave'
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  height: 100%;
}

.max-width-100 {
  max-width: 100%;
}
.max-width-1250 {
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}
.col-center {
  text-align: center;
}
.full-height {
  height: 100%;
}

.links {
  text-align: right;
}
.link {
  color: #ff01b9;
  a:visited {
    color: #ff01b9;
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
