<template>
  <v-container>
    <v-row>
      <v-col cols="2">
        <h3>Models</h3>
      </v-col>
      <v-col cols="10">
        <p>
          Mission Squad supports variations of the newer OpenAI Completion type APIs. If you're using <a
            class="link-inline" href="https://platform.openai.com/" target="_blank">OpenAI</a> or <a class="link-inline"
            href="https://lmstudio.ai/" target="_blank">LM Studio</a>, choose "OpenAI Completion"
          from the dropdown menu. If you're using <a class="link-inline" href="https://infermatic.ai/"
            target="_blank">Infermatic</a>,
          choose "Infermatic (OpenAI Completion)", and if you're using <a class="link-inline"
            href="https://www.anthropic.com/" target="_blank">Anthropic</a>'s api, choose
          "Anthropic Messages". These are very similar, but there are slight differences in requests to and
          responses from the APIs.
        </p><br />
        <p>
          The save button (<v-icon color="primary" size="x-small">mdi-check-bold</v-icon>) will be enabled once all
          required fields are filled in and the API has been tested.
          You can test the API by either clicking the autofill icon (<v-icon color="primary"
            size="small">mdi-lightning-bolt-outline</v-icon>) or the <v-btn color="primary" variant="text"
            density="compact" class="pa-0 ma-0">TEST</v-btn> button at the bottom of the model card. The autofill button
          will
          fill in the description with the model's name from the API response, and a dropdown will appear at the bottom
          of the card with the model's text response.
        </p><br />
        <p>
          Unless you're using LM Studio with only one model loaded, you will need to either enter the model's name in
          the API Models field,
          or select the model from the dropdown that will show when the <v-checkbox color="secondary" label="More"
            density="compact" class="inline" v-model="selected"
            hide-details />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          box
          is checked. The refresh button (<v-icon color="primary" size="x-small">mdi-sync</v-icon>) will populate
          the dropdown list if it's empty and if the API supports the "/models" endpoint.
        </p>
        <img src="/img/how-to-models1.png" class="max-width-100" alt="Models Screenshot" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <h3>Agents</h3>
      </v-col>
      <v-col cols="10">
        <p>
          <b>All sections from Agents to Missions heavily rely on prompting to get the model to respond in a way to
            continue the task.</b>
        </p><br />
        <p>
          Agents are pretty straight forward in that the goal is to get the model to only respond as if it were assuming
          the role that you give it.
          In other words, you provide instructions, via the Agent Prompt, to the model and it should respond as if
          that's the only job it does. Some
          types of roles may require more precise responses than others. For example, you may require an engineer or
          "coder" agent, to only respond with
          code and no other text. This will need to be specified in the Agent Prompt. There is an autofill (<v-icon
            color="primary" size="small">mdi-lightning-bolt-outline</v-icon>) feature that will attempt to fill in the
          Agent
          Prompt based on the Agent's Description. Once you fill in the Agent Name, Description and choose the Model,
          the autofill button
          will be enabled. You may need to edit or fine-tune this prompt. This Agent Prompt is used as the "system"
          message when prompting the model.
        </p><br />
        <p>
          Optionally, you may have the agent's response sent to another agent. This could be useful in a situation when
          you have 2 agents that don't
          directly interact, but one needs to know about the other's responses. For example, an agent that is tasked
          with reviewing output from another
          agent, it may need to know what that agent was told to do.
        </p>
        <img src="/img/how-to-agents1.png" class="max-width-100" alt="Agents Screenshot" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <h3>Squads</h3>
      </v-col>
      <v-col cols="10">coming soon...</v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <h3>Missions</h3>
      </v-col>
      <v-col cols="10">coming soon...</v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <h3>Prompt Studio</h3>
      </v-col>
      <v-col cols="10">coming soon...</v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <h3>Options</h3>
      </v-col>
      <v-col cols="10">
        <p>
          Options are found under the menu that appears when you click on the 3 vertical dots
          (<v-icon size="small">mdi-dots-vertical</v-icon>) in the top right corner.
        </p><br />
        <p>
          Here you will find all of the internal prompts that are used to facilitate autofill features and squad
          functions. You may find that based
          on your use case or the model you're using, you may need to edit these prompts. Most of the prompts have some
          optional dynamic content that will be
          filled in when executing that prompt request.
        </p><br />
        <p>
          Near the bottom of the screen, you will find a few buttons for convenience features like exporting and
          clearing your configs. The "Open Logs" button
          will open the log file where all actions within the app get logged. If you're having trouble, you may find
          this helpful.
        </p><br />
        <p>
          Lastly, there's a checkbox to send telemetry data. This data is anonymous (meaning we can't tell who you are)
          and
          doesn't contain any sensitive information.
          This will help us improve the app and make it better for you. This is turned off by default, but please
          consider turning it on.
        </p>
        <img src="/img/how-to-options1.png" class="max-width-100" alt="Options Screenshot" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <h3 ref="windows">Windows</h3>
      </v-col>
      <v-col cols="10">
        <p>
          1. Install WSL<br />
          2. Install mission squad with `dpkg -i missionsquad_{{version}}_amd64.deb`<br />
          3. Run mission squad with `missionsquad`
        </p>
        <p>
          Notes:<br />
          - To connect to an API running on your local host, use the local network IP address of the host machine
          instead of localhost.<br />
          example: http://192.168.1.100:1234/v1 instead of http://localhost:1234/v1<br />
          - You may need to install other dependencies to install WSL and you may need to adjust your firewall settings
          to allow the application to connect to external services.<br />
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  computed: {
    breakpoint() {
      return this.$vuetify.display.name
    }
  }
})
export default class HowToView extends Vue {
  breakpoint!: string
  selected = true
  version: string = '1.4.1'

  beforeCreate() { }

  mounted() {
    this.scrollToSection()
  }

  updated() {
    this.scrollToSection()
  }

  scrollToSection() {
    const section = this.$route.params.section
    if (section != null && section === 'windows') {
      const el = this.$refs.windows
      if (el != null) {
        //@ts-ignore
        el.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.inline {
  display: inline-block;
  position: absolute;
  margin-top: -2px;
}
.v-input--density-compact {
  --v-input-control-height: 20px !important;
}
.link-inline {
  color: #ffffff;
}
</style>